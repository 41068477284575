import AuthModule from './auth';

function listObjects(path, cb) {
  if (!AuthModule.getCurrentUser()) return cb(new Error(`Firebase not ready. Please wait.`));
  if (!path) return cb(new Error(`Bad path`));
  const storageRef = AuthModule.getFireStorage().ref(path);
  storageRef.listAll().then((objects) => {
    cb(null, objects);
  }).catch((err) => {
    cb(err);
  });
}

function getObject(path, cb) {
  if (!AuthModule.getCurrentUser()) return cb(new Error(`Firebase not ready. Please wait.`));
  if (!path) return cb(new Error(`Bad path`));
  const storageRef = AuthModule.getFireStorage().ref(path);
  storageRef.getDownloadURL().then((objects) => {
    cb(null, objects);
  }).catch((err) => {
    cb(err);
  });
}

// function deleteAsset(path, cb) {
//   if (!AuthModule.getCurrentUser()) return cb(new Error(`Firebase not ready. Please wait.`));
//   if (!filePath) return cb(new Error(`Bad file path`));
//   const firstIndex = path.indexOf('__');
//   const folderPath = path.slice(0, firstIndex);
//   const filePath = path.slice(firstIndex + 2);
//   const storageRef = AuthModule.getFireStorage().ref(folderPath);
//   const ggRef = storageRef.child(filePath);
//   ggRef.delete().then(() => {
//     cb(null);
//   }).catch((err) => {
//     cb(err);
//   });
// }

function uploadAsset(file, itemType, assetType, cb) {
  if (!AuthModule.getCurrentUser()) return cb(new Error(`Firebase not ready. Please wait.`));
  const id = (new Date()).getTime() + '_' + Math.round(Math.random() * 100).toString();
  let folderPath = null;
  let itemTypePath = null;
  switch (itemType) {
    case 1:
      folderPath = 'playlists';
      itemTypePath = 'playlist';
      break;
    case 2:
      folderPath = 'tracks';
      itemTypePath = 'track';
      break;
  }
  if (!folderPath) return cb(`Bad file type`);
  const fileName = `${assetType === 1 ? 'audio' : 'image'}/${itemTypePath}_${file.name}_${id}`;
  const storageRef = AuthModule.getFireStorage().ref(folderPath);
  const ggRef = storageRef.child(fileName);
  const uploadRef = ggRef.put(file);
  uploadRef.on(`state_changed`, snapshot => {
      let percentage = (Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 10000) / 100).toString() + '%';
      cb(null, {percentage: percentage})
    }, err => {
      console.warn(`uploadAsset upload get err -`, err);
      cb(err.message)
    },
    () => {
      cb(null, {percentage: '100%'});
      uploadRef.snapshot.ref.getDownloadURL().then((url) => {
        cb(null, {
          id: {
            filePath: folderPath + '/' + fileName,
            storagePath: AuthModule.firebaseConfig.storageBucket
          },
          url});
      }).catch((err) => {
        console.warn(`uploadAsset getDownloadURL get err -`, err);
        cb(err.message);
      });
    });
}

export default {
  //deleteAsset,
  getObject,
  listObjects,
  uploadAsset
};

let makeArrayCounter = 0;

function processItems(items) {
  let processItems = [];
  makeArrayCounter = 0;

  items.forEach((item) => {
    if (!item.parentId) {
      processItems.push(item);
    } else {
      let foundedItem = findItem(item, items);
      if (foundedItem.children) foundedItem.children.push(item);
      else foundedItem.children = [item];
    }
  });
  processItems = sortItems(processItems);
  let result = makeArray(processItems);
  return result;
}

function findItem(item, actualItems) {
  let foundedItem = null;
  actualItems.some(function (searchedItem) {
    if (searchedItem.id === item.parentId) {
      foundedItem = searchedItem;
      return true;
    } else if (searchedItem.children) {
      foundedItem = findItem(item, searchedItem.children);
      return !!foundedItem;
    }
  });
  return foundedItem;
}

function sortItems(items) {
  items.sort(sortByOrder);
  items.forEach((item) => {
    if (item.children) {
      sortItems(item.children);
    }
  });
  return items;
}

function makeArray(items) {
  let result = [];
  items.forEach((item) => {
    item.calculatedOrder = makeArrayCounter;
    result.push(item);
    makeArrayCounter++;
    if (item.children) {
      let tempArray = makeArray(item.children);
      result = result.concat(tempArray);
      delete item.children;
    }
  });
  return result;
}

function sortByOrder(a, b) {
  if (a.order && b.order) return a.order - b.order;
  else if (a.order) return -1;
  else if (b.order) return 1;
  else return a > b ? -1 : 1;
}

exports.processItems =  processItems;
exports.mainUrl = 'https://us-central1-stickme-675c7.cloudfunctions.net/webApi';

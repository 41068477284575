import axios from 'axios';

import AuthModule from '../../modules/auth';
import Helpers from '../../modules/helpers';

const state = () => ({
  categories: [],
  categoriesFeedOrder: []
});

const getters = {
  getCategories: (state) => {
    return state.categories;
  },
  getCategoriesFeedOrder: (state) => {
    return state.categoriesFeedOrder;
  }
};

const actions = {
  setCategories({commit}) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.get(`${Helpers.mainUrl}/api/v1/categories`, {
          headers: {
            Authorization: "Bearer " + token
          }
        }).then(res => {
          commit('setCategories', res.data.payload);
          resolve(true);
        }).catch(err => {
          console.warn(`HTTP setStickerPacks get err - ${err.message}`);
          reject({
            message: `HTTP setStickerPacks get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  createCategory({commit}, data) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.post(`${Helpers.mainUrl}/api/v1/categories/create`, data, {
          headers: {
            Authorization: "Bearer " + token
          }
        }).then(res => {
          commit('createCategory', res.data.payload);
          resolve(true);
        }).catch(err => {
          console.warn(`HTTP createCategory get err - ${err.message}`);
          reject({
            message: `HTTP createCategory get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  updateCategory({commit}, data) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.put(`${Helpers.mainUrl}/api/v1/categories/update`, data, {
          headers: {
            Authorization: "Bearer " + token
          }
        }).then(res => {
          console.log(res);
          commit('updateCategory', res.data.payload);
          resolve(true);
        }).catch(err => {
          console.warn(`HTTP updateCategory get err - ${err.message}`);
          reject({
            message: `HTTP updateCategory get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  deleteCategory({commit}, categoryId) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.delete(`${Helpers.mainUrl}/api/v1/categories/delete?categoryId=${categoryId}`, {
          headers: {
            Authorization: "Bearer " + token
          }
        }).then(res => {
          console.log(res);
          commit('deleteCategory', categoryId);
          resolve(true);
        }).catch(err => {
          console.warn(`HTTP deleteCategory get err - ${err.message}`);
          reject({
            message: `HTTP deleteCategory get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  setCategoriesFeedOrder({commit}) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.get(`${Helpers.mainUrl}/api/v1/feed/feedConfig`, {
          headers: {
            Authorization: "Bearer " + token
          }
        }).then(res => {
          commit('setCategoriesFeedOrder', res.data.payload);
          resolve(true);
        }).catch(err => {
          console.warn(`HTTP setCategoriesFeedOrder get err - ${err.message}`);
          reject({
            message: `HTTP setCategoriesFeedOrder get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  updateCategoriesFeedOrder({commit}, data) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.put(`${Helpers.mainUrl}/api/v1/feed/updateFeedConfig`, data, {
          headers: {
            Authorization: "Bearer " + token
          }
        }).then(() => {
          commit('setCategoriesFeedOrder', data.categories);
          resolve(true);
        }).catch(err => {
          console.warn(`HTTP updateCategoriesFeedOrder get err - ${err.message}`);
          reject({
            message: `HTTP updateCategoriesFeedOrder get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  }
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  createCategory(state, category) {
    state.categories.push(category);
  },
  updateCategory(state, category) {
    state.categories = state.categories.filter((item) => {
      return item.id !== category.id;
    });
    state.categories.push(category);
  },
  deleteCategory(state, categoryId) {
    state.categories = state.categories.filter((category) => {
      return category.id !== categoryId;
    });
  },
  setCategoriesFeedOrder(state, categoriesFeedOrder) {
    state.categoriesFeedOrder = categoriesFeedOrder;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

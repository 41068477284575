<template>
    <div class="stickerPacks">
        <div class="componentBody">
            <div style="display: flex">
                <div class="componentMenu flexColumn">
                    <span>Sticker packs: </span>
                    <span class="pseudo-button" v-on:click="listStickerPacks">&#x21bb;</span>
                    <span class="pseudo-button" v-on:click="showCreateStickerPackModal">+</span>
                </div>
                <div class="flexColumn" style="margin: 5px auto;">
                    <span>Show stickers?: </span>
                    <label class="switch">
                        <input type="checkbox" v-model="isShowStickers">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="flexTable">
                <div class="flexColumn">
                    <div class="flexCell" style="flex-grow: 1.4;">Name
                        <span v-if="nameSortIsAsc === 1" style="cursor: pointer; font-size: 30px;" v-on:click="changeNameSort(false)">&#8593;</span>
                        <span v-if="nameSortIsAsc === 2" style="cursor: pointer; font-size: 30px;" v-on:click="changeNameSort(true)">&#8595;</span>
                        <span v-if="nameSortIsAsc === 3" style="cursor: pointer; font-size: 30px;" v-on:click="changeNameSort(true)">&#8621;</span>
                    </div>
                    <div class="flexCell" style="flex-grow: 1;">Updated date
                        <span v-if="updateAtSortIsAsc === 1" style="cursor: pointer; font-size: 30px;" v-on:click="changeUpdateAtSort(false)">&#8593;</span>
                        <span v-if="updateAtSortIsAsc === 2" style="cursor: pointer; font-size: 30px;" v-on:click="changeUpdateAtSort(true)">&#8595;</span>
                        <span v-if="updateAtSortIsAsc === 3" style="cursor: pointer; font-size: 30px;" v-on:click="changeUpdateAtSort(true)">&#8621;</span>
                    </div>
                    <div class="flexCell">Is premium?</div>
                    <div class="flexCell">View / Download</div>
                    <div class="flexCell" style="flex-grow: 1;">Categories</div>
                    <div class="flexCell" style="flex-grow: 1;">Cover</div>
                    <div v-if="isShowStickers" class="flexCell" style="flex-grow: 3;">Stickers</div>
                    <div class="flexCell">Actions</div>
                </div>
                <div class="flexColumn" v-for="stickerPack in stickerPacks" :key="stickerPack.sortIndex">
                    <div class="flexCell" style="flex-grow: 1.4;">{{stickerPack.name}}</div>
                    <div class="flexCell" style="flex-grow: 1;">{{stickerPack.updatedAtDate}}
                    </div>
                    <div class="flexCell">{{stickerPack.premium}}</div>
                    <div class="flexCell">{{stickerPack.viewed}}/{{stickerPack.downloaded}}</div>
                    <div class="flexCell" style="flex-grow: 1;">
                        <span class="categoryPreview" v-for="(category, index) in stickerPack.categories"
                              :key="index">{{(categories.find((item) => item.id === category) || {name: {en: ''} }).name['en']}}</span>
                    </div>
                    <div class="flexCell" style="flex-grow: 1;">
                        <img class="imagePreview"
                             v-bind:src="stickers[stickerPack.id] ? (stickers[stickerPack.id].find((item) => {return item.stickerId === stickerPack.coverId}) || {stickerUrl: emptyImage}).stickerUrl : emptyImage"/>
                    </div>
                    <div v-if="isShowStickers" class="flexCell flexRow" style="flex-grow: 3;">
                        <img class="imagePreview" v-for="(sticker, index) in stickers[stickerPack.id]" :key="index"
                             v-bind:src="sticker.stickerUrl"/>
                    </div>
                    <div class="flexCell">
                        <b class="pseudo-button" v-on:click="showEditStickerPack(stickerPack)">&#x270e;</b>
                        <b class="pseudo-button" v-on:click="showDeleteStickerPack(stickerPack)">&#x1f5d1;</b>
                    </div>
                </div>
            </div>
            <pulse-loader class="spinnerPosition" :loading="isShowSpinner"></pulse-loader>
        </div>
        <div v-if="isShowCreateOrUpdateStickerPackModal" class="modalWindow">
            <div class="modalWindowClose" v-on:click="cancelAnyModal">x</div>
            <div class="modalWindowBody">
                <div class="flexColumn">
                    <div class="leftFlexColumn">
                        <span>Name:</span>
                    </div>
                    <div class="rightFlexColumn">
                        <input type="text" v-model="newName"/>
                    </div>
                </div>
                <div class="flexColumn">
                    <div class="leftFlexColumn">
                        <span>Author:</span>
                    </div>
                    <div class="rightFlexColumn">
                        <input type="text" disabled v-model="newAuthor"/>
                    </div>
                </div>
                <div class="flexColumn">
                    <div class="leftFlexColumn">
                        <span>Is premium:</span>
                    </div>
                    <div class="rightFlexColumn">
                        <input type="checkbox" v-model="newPremium"/>
                    </div>
                </div>
                <div class="flexColumn">
                    <div class="leftFlexColumn">
                        <span>Is hidden:</span>
                    </div>
                    <div class="rightFlexColumn">
                        <input type="checkbox" v-model="newIsHidden"/>
                    </div>
                </div>
                <div class="flexColumn">
                    <div class="leftFlexColumn">
                        <span>Downloaded:</span>
                    </div>
                    <div class="rightFlexColumn">
                        <input type="text" v-model="newDownloaded"/>
                    </div>
                </div>
                <div class="flexColumn">
                    <div class="leftFlexColumn">
                        <span>Viewed:</span>
                    </div>
                    <div class="rightFlexColumn">
                        <input type="text" v-model="newViewed"/>
                    </div>
                </div>
                <SelectCategories :inputArray="activeStickerPack ? activeStickerPack.categories : []"
                                  v-on:pushResult="newCategoriesResult"/>
                <br>
                <AssetUpload v-on:pushError="updatedError"
                             :inputData="stickers && activeStickerPack ? stickers[activeStickerPack.id] : []"
                             :coverData="activeStickerPack ? activeStickerPack.coverId : null"
                             v-on:pushResult="newStickersResult"/>
                <br>
                <div>
                    <b v-if="!activeStickerPack" class="pseudo-button" v-on:click="acceptCreateStickerPack()">Ok</b>
                    <b v-if="activeStickerPack" class="pseudo-button" v-on:click="acceptEditStickerPack()">Ok</b>
                    <b class="pseudo-button" v-on:click="cancelAnyModal()">Cancel</b>
                </div>
            </div>
        </div>
        <div v-if="isShowDeleteStickerPackModal" class="modalWindow delete">
            <div class="modalWindowClose" v-on:click="cancelAnyModal">x</div>
            <div class="modalWindowBody">
                <div>
                    <div style="margin: 5px;">Are you want delete category with name = {{activeStickerPack.name}}</div>
                    <div>
                        <b class="pseudo-button" v-on:click="acceptDeleteStickerPack()">Ok</b>
                        <b class="pseudo-button" v-on:click="cancelAnyModal()">Cancel</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import AssetUpload from './AssetUpload';
  import SelectCategories from './SelectCategories';
  import AuthModule from '../modules/auth';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: "StickerPacks",
    props: ['logged'],
    components: {
      AssetUpload,
      PulseLoader,
      SelectCategories
    },
    computed: {
      ...mapGetters('stickerPacks', {
        stickerPacks: 'getStickerPacks',
        stickers: 'getStickers'
      }),
      ...mapGetters('categories', {
        categories: 'getCategories'
      })
    },
    data() {
      return {
        emptyImage: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKC" +
          "wsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQ" +
          "UFBQUFBQUFBQUFBQUFBQUFBT/wAARCABQAGQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAw" +
          "UFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZW" +
          "ZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn" +
          "6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMz" +
          "UvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqK" +
          "mqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD7F6mkzgmlo9aAACjPFAooATIpc0UUAFG" +
          "aKKADNIeDS0d6AAdaM0CigBD1oobrRQAvekH3jS0n8RoAB0pRSDpWvpHhbVNbINrZuYz/AMtZPkT8z1/DNAGR1/OnxRPPKscaNJI3ARAST+Ar0bS/" +
          "hZBChm1G5e6ZesFpwCfTJ5P6VclOr6VG0OgeGUsk6efK6Fz74DfzJoA5TTPh1qd3F5135el2wGWe4PzAf7vb8SKo+LPDf/CNajHCspuIJYhJHKQB" +
          "u7Hp7/zFaWqeG/F2svuvbee49FaVNo+ig4Fbup+GdT1jwVYRTWrLqli+xULLl06dc46YP/AaAPNaQ/eFbOoeEdX0u0e5urJoYExucupxk4HQ+prG/" +
          "ioAXuaKO5ooARutFDdaKAFyKTOCaXHvSYyTQAA8V37and6t8Nlkt7mWKfT3Ec4jbaXjHAzjnoQfwNcAOldb8OL9I9Wm0645ttQiMTKem4A4/Mbh+V" +
          "AFz+0bnTvhnZTWtxJbym8YF42wSMvkZps1j4pg8OjV21ibytglMImO8Ie/p05xUmv6e+l/D2Gzkzvh1FkJ9RufB/EYNYun32veILD+xbV3uLdEyYw" +
          "AMKOgLenoKAKn/CV60SANVvCTwAJTUlx4j160nkhm1K9iljbayNIcg+ldH8OvCEkt82oX0LxpbOViilXBMg6kg+n8/pW/498L2l3pF/qEVuov1VZDK" +
          "OrBeo/75z+VAHOwaldal8NNWlu7iS5kW5VQ0rZIGU4/WuFzzXZaX/yTHWOf+Xpf5x1xv8QoAAeaXNIBg0tADSc0UHrRQA7NJn5jS5GaTPzGgBQakt7" +
          "iS0uIp4jtliYOh9wcioh0pc0Aet+Lrefxb4Ss5NNhM7TSRzbAQMDac9SOhOKoeHU1zw3YC3t/DIZjzJM10oaRvU/0HavP7bXNQs4Vhgv7mGJeiRys" +
          "FH4ZqX/hJdWz/wAhS7/7/t/jQB6h/b/ib/oWh/4FrTJda8RzxvG/hlWR1KsPta8g8GvMv+El1b/oKXf/AH/b/Gj/AISXVv8AoKXf/f8Ab/GgDrf7H" +
          "vNE+HGrQXsBt5GuFdVLA/LlB2PtXAHqKvXOuaheQtDPf3E8TdUklLKfwJqj/FQAveikHU0uaAEPWikPWigB2T6UZ5pufalPU8UAKDntRmm0v4UAGf" +
          "al/Cm/hR+FADs+1H4U38KO3SgB2T6UE803PHSjigBwNGaQdaAenFACHrRRRQB//9k=",
        //
        isShowCreateOrUpdateStickerPackModal: false,
        isShowDeleteStickerPackModal: false,
        isShowUpdateCoverModal: false,
        isShowSpinner: false,
        spinnerCounter: 0,
        //
        nameSortIsAsc: 1,
        updateAtSortIsAsc: 3,
        isShowStickers: false,
        //
        newName: null,
        newAuthor: null,
        newPremium: false,
        newIsHidden: false,
        newDownloaded: 0,
        newViewed: 0,
        newStickers: [],
        newCategories: [],
        stickersForDelete: [],
        stickerForCover: null,
        activeStickerPack: null
      }
    },
    methods: {
      ...mapActions('stickerPacks', ['setStickerPacks', 'createStickerPack', 'updateStickerPack',
        'deleteStickerPack', 'uploadSticker', 'deleteSticker', 'updateCover', 'setStickerPacksSortType']),
      ...mapActions('categories', ['setCategories']),
      updatedError: function (err) {
        this.$emit('bodyComponentResult', ({err: err}));
      },
      listStickerPacks: function () {
        this.showSpinner();
        // Get stickers pack list
        this.setStickerPacks().then(() => {
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
        }).finally(() => {
          this.hideSpinner();
        });
      },
      listCategories: function () {
        this.showSpinner();
        // Get categories list
        this.setCategories().then(() => {
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
        }).finally(() => {
          this.hideSpinner();
        });
      },
      changeNameSort: function (isAsc) {
        this.nameSortIsAsc = isAsc ? 1 : 2;
        this.updateAtSortIsAsc = 3;
        this.setStickerPacksSortType({
          attr: 'name',
          isAsk: isAsc
        });
      },
      changeUpdateAtSort: function (isAsc) {
        this.updateAtSortIsAsc = isAsc ? 1 : 2;
        this.nameSortIsAsc = 3;
        this.setStickerPacksSortType({
          attr: 'updatedAt',
          isAsk: isAsc
        });
      },
      newCategoriesResult: function (data) {
        if (data.selectedCategories) this.newCategories = data.selectedCategories;
      },
      newStickersResult: function (data) {
        if (data.newStickers) this.newStickers = data.newStickers;
        else if (data.stickerForDelete) this.stickersForDelete.push(data.stickerForDelete);
        else if (data.stickerForCover) this.stickerForCover = data.stickerForCover;
        else if (data.stickerForCoverDeleted) this.stickerForCover = null;
      },
      showCreateStickerPackModal: function () {
        this.isShowCreateOrUpdateStickerPackModal = true;
        this.newAuthor = AuthModule.getAuthor();
      },
      acceptCreateStickerPack: function () {
        this.showSpinner();
        let viewed = parseInt(this.newViewed);
        let downloaded = parseInt(this.newDownloaded);
        this.createStickerPack({
          name: this.newName,
          author: this.newAuthor,
          premium: this.newPremium,
          isHidden: this.newIsHidden,
          downloaded: isNaN(downloaded) ? 0 : downloaded,
          viewed: isNaN(viewed) ? 0 : viewed,
          categories: this.newCategories.map((item) => item.id)
        }).then(async (stickerPack) => {
          const promises = [];
          if (this.newStickers.length) {
            this.newStickers.forEach((item) => {
              if (item.file && item.fileSrc) {
                const splitdData = item.fileSrc.split(';base64,');
                if (splitdData.length < 2) return alert(`Problem with ${item.file.name}`);
                promises.push(this.uploadSticker({
                  file: splitdData[1],
                  contentType: item.file.type,
                  packageId: stickerPack.id,
                  userId: stickerPack.userId
                }));
              }
            });
            let warnFlag = false;
            let stickerCoverId = null;
            const results = await Promise.all(promises);
            this.newStickers.forEach((item, index) => {
              if (this.stickerForCover && this.stickerForCover.newId && item.newId === this.stickerForCover.newId) {
                stickerCoverId = results[index];
              }
            });
            if (!results) warnFlag = true;
            if (this.stickerForCover) {
              if (this.stickerForCover.stickerId) stickerCoverId = this.stickerForCover.stickerId;
              stickerPack.coverId = stickerCoverId;
              try {
                await this.updateCover({
                  stickerPack: stickerPack,
                  dataForUpdateCover: {
                    coverId: stickerCoverId,
                    stickerPackId: stickerPack.id
                  }
                });
              } catch (err) {
                warnFlag = true;
                alert(err.message);
              }
            }
            if (warnFlag) alert('Sticker pack create but something bad with upload stickers. Please refresh page and try edit sticker pack.');
            else alert(`Sticker pack created`);
          } else alert(`Sticker pack created`);
          this.cancelAnyModal();
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
          this.cancelAnyModal();
        });
      },
      showEditStickerPack: function (stickerPack) {
        this.activeStickerPack = stickerPack;
        this.isShowCreateOrUpdateStickerPackModal = true;
        this.newName = stickerPack.name;
        this.newAuthor = stickerPack.author;
        this.newPremium = stickerPack.premium;
        this.newIsHidden = stickerPack.isHidden;
        this.newDownloaded = stickerPack.downloaded;
        this.newViewed = stickerPack.viewed;
      },
      acceptEditStickerPack: function () {
        this.showSpinner();
        let viewed = parseInt(this.newViewed);
        let downloaded = parseInt(this.newDownloaded);
        this.updateStickerPack({
          id: this.activeStickerPack.id,
          name: this.newName,
          author: this.newAuthor,
          premium: this.newPremium,
          isHidden: this.newIsHidden,
          downloaded: isNaN(downloaded) ? 0 : downloaded,
          viewed: isNaN(viewed) ? 0 : viewed,
          categories: this.newCategories.map((item) => item.id)
        }).then(async (stickerPack) => {
          let promises = [];
          let warnFlag = false;
          let results = null;
          let stickerCoverId = null;
          if (this.newStickers.length) {
            this.newStickers.forEach((item) => {
              if (item.file && item.fileSrc) {
                const splitdData = item.fileSrc.split(';base64,');
                if (splitdData.length < 2) return alert(`Problem with ${item.file.name}`);
                promises.push(this.uploadSticker({
                  file: splitdData[1],
                  contentType: item.file.type,
                  packageId: stickerPack.id,
                  userId: stickerPack.userId
                }));
              }
            });
            results = await Promise.all(promises);
            this.newStickers.forEach((item, index) => {
              if (this.stickerForCover && this.stickerForCover.newId && item.newId === this.stickerForCover.newId) {
                stickerCoverId = results[index];
              }
            });
            if (!results) warnFlag = true;
          }
          promises = [];
          if (this.stickersForDelete.length) {
            this.stickersForDelete.forEach((item) => {
              promises.push(this.deleteSticker({
                stickerId: item.stickerId,
                packageId: stickerPack.id,
                userId: stickerPack.userId
              }));
            });
            let result = await Promise.all(promises);
            if (!result) warnFlag = true;
          }
          if (this.stickerForCover) {
            if (this.stickerForCover.stickerId) stickerCoverId = this.stickerForCover.stickerId;
            this.activeStickerPack.coverId = stickerCoverId;
            try {
              await this.updateCover({
                stickerPack: this.activeStickerPack,
                dataForUpdateCover: {
                  coverId: stickerCoverId,
                  stickerPackId: this.activeStickerPack.id
                }
              });
            } catch (err) {
              warnFlag = true;
              alert(err.message);
            }
          }
          if (warnFlag) alert('Sticker pack updated but something bad with upload stickers. Please refresh page and try edit sticker pack.');
          else alert('Sticker pack updated');
          this.cancelAnyModal();
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
          this.cancelAnyModal();
        });
      },
      showDeleteStickerPack: function (stickerPack) {
        this.activeStickerPack = stickerPack;
        this.isShowDeleteStickerPackModal = true;
      },
      acceptDeleteStickerPack: function () {
        this.showSpinner();
        this.deleteStickerPack(this.activeStickerPack.id).then((result) => {
          if (result) alert(`Sticker pack deleted`);
          this.cancelAnyModal();
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
          this.cancelAnyModal();
        });
      },
      cancelAnyModal: function () {
        this.isShowCreateOrUpdateStickerPackModal = false;
        this.isShowDeleteStickerPackModal = false;
        this.isShowUpdateCoverModal = false;
        this.hideSpinner();
        //
        this.newName = null;
        this.newAuthor = null;
        this.newPremium = false;
        this.newDownloaded = 0;
        this.newViewed = 0;
        this.newStickers = [];
        this.stickersForDelete = [];
        this.activeStickerPack = null;
      },
      showSpinner: function () {
        this.spinnerCounter++;
        this.isShowSpinner = true;
      },
      hideSpinner: function () {
        if (--this.spinnerCounter < 1) this.isShowSpinner = false;
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.listStickerPacks();
        this.listCategories();
      });
    }
  }
</script>

<style scoped>
    .pseudo-button {
        display: inline-block;
        border: solid 2px wheat;
        padding: 5px;
        margin: 2px 3px;
        line-height: 30px;
        border-radius: 30px;
        cursor: pointer;
        font-weight: bold;
        font-size: 25px;
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
    }

    .modalWindow {
        z-index: 1;
        padding: 35px 0 5px 0;
        width: 60%;
        height: 90%;
        position: fixed;
        top: 2%;
        border: solid 3px cornflowerblue;
        border-radius: 10px;
        left: 25%;
        background: aliceblue;
        font-size: 25px;
    }

    .modalWindow.delete {
        top: 40%;
        height: 20%;
    }

    .modalWindowBody {
        height: 97%;
        overflow: auto;
    }

    .modalWindowBody input {
        margin: 4px 0;
        padding: 0;
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 20px;
    }

    .modalWindowBody input[type="checkbox"] {
        width: 20px;
    }

    .modalWindowClose {
        right: -2px;
        top: -4px;
        position: absolute;
        display: block;
        width: 25px;
        height: 25px;
        line-height: 25px;
    }

    .stickerPacks {
        line-height: 30px;
        font-size: 25px;
    }

    .componentBody {
        margin: 5px 0;
    }

    .componentMenu {
        border: solid 2px wheat;
        width: 20%;
        margin: 5px auto;
        padding: 5px 0;
        border-radius: 5px;
    }

    .flexTable {
        display: flex;
        flex-direction: column;
        border: solid 1px wheat;
    }

    .flexRow {
        display: flex;
        flex-wrap: wrap;
    }

    .flexColumn {
        display: flex;
        flex-direction: row;
    }

    .flexCell {
        min-width: 80px;
        border: solid 1px sandybrown;
        flex-basis: 80px;
        overflow-wrap: anywhere;
        word-break: break-all;
        text-overflow: ellipsis;
    }

    .leftFlexColumn {
        text-align: right;
        flex-basis: 20%;
        margin: 0 5px;
    }

    .rightFlexColumn {
        text-align: left;
        flex-basis: 78%;
        margin: 0 5px;
    }

    .imagePreview {
        max-height: 100px;
        min-width: 90px;
        margin: 3px 3px;
        border: solid 1px darkslateblue;
    }

    .categoryPreview {
        text-overflow: ellipsis;
        border: solid 1px cornflowerblue;
        border-radius: 3px;
        box-shadow: 0 0 2px 1px dimgray;
        background: #42b983;
        margin: 3px 3px;
        padding: 1px 2px;
        line-height: 36px;
    }

    /* Rounded sliders */
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>

const uploadFile = function (file, cb) {
  if (file.size === 0) {
    alert(`File have 0 size`);
    return cb(null);
  }
  if (file.size > 32 * 1024 * 1024) {
    alert(`File have size bigger than 32 mb`);
    return cb(null);
  }
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  // как только файл загружен
  fileReader.onload = (data) => {
    console.log(data);
    const splitData = data.target.result.split(';');
    if (splitData.length !== 2) return cb(null);
    const splitFileType = splitData[0].split(':');
    if (splitFileType.length !== 2) return cb(null);
    const fileType = splitFileType[1];
    const splitFileData = splitData[1].split(',');
    if (splitFileData.length !== 2) return cb(null);
    const fileData = splitFileData[1];
    return cb({
      type: fileType,
      name: file.name
    }, atob(fileData), data.target.result);
  };
};

export default {
  uploadFile
};

export {
  uploadFile
};

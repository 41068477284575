<template>
    <div class="main">
        <div class="spinnerPosition" v-if="isShowSpinner">
            <pulse-loader :loading="true"></pulse-loader>
        </div>
        <div>Select sticker files: <input type="file" multiple @change="changeAsset" @click="clickOnAsset"/></div>
        <div class="flexTable">
            <div class="flexColumn" v-for="(sticker, index) in stickers" :key="index">
                <div style="position: relative;">
                    <div class="pseudoButton closeButton" v-on:click="deleteSticker(sticker)">X</div>
                    <div v-if="sticker.selected" class="cover">Cover</div>
                    <img v-bind:src="sticker.fileSrc || sticker.stickerUrl" v-bind:class="{ previewSelected: sticker.selected, preview: !sticker.selected }" v-on:click="selectSticker(sticker)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import UploadFile from '../modules/uploadFile';

  export default {
    name: "AssetUpload",
    components: {
      PulseLoader
    },
    props: {
      inputData: Array,
      coverData: String
    },
    data: function () {
      return {
        stickers: [],
        coverId: null,
        actualStickers: [],
        newStickers: [],
        isShowSpinner: false
      }
    },
    methods: {
      changeAsset: function (event) {
        this.isShowSpinner = true;
        if (event.target.files.length) {
          let counter = event.target.files.length;
          for (const file of event.target.files) {
            UploadFile.uploadFile(file, (info, content, rawData) => {
              if (info) {
                if (!rawData.startsWith('data:image')) {
                  alert(`Bad file type with name ${file.name}`);
                } else {
                  this.newStickers.push({
                    newId: (new Date()).getTime() + '_' + file.size,
                    info,
                    file,
                    fileSrc: rawData
                  });
                  this.stickers = this.actualStickers.concat(this.newStickers);
                  this.stickers = this.stickers.map((item) => {
                    item.selected = (item.stickerId === this.coverId || item.newId === this.coverId);
                    return item;
                  });
                  this.$emit('pushResult', ({newStickers: this.newStickers}));
                }
              }
              if (--counter < 1) {
                this.isShowSpinner = false;
              }
            });
          }
        }
      },
      clickOnAsset: function (event) {
        event.target.value = '';
      },
      selectSticker: function (sticker) {
        this.coverId = sticker.stickerId || sticker.newId;
        this.stickers = this.stickers.map((item) => {
          item.selected = (item.stickerId === this.coverId || item.newId === this.coverId);
          return item;
        });
        this.$emit('pushResult', ({stickerForCover: sticker}));
      },
      deleteSticker: function (sticker) {
        if (this.coverId === sticker.stickerId || this.coverId === sticker.newId) {
          this.coverId = null;
          this.$emit('pushResult', ({stickerForCoverDeleted: true}));
        }
        if (sticker.stickerId) {
          this.actualStickers = this.actualStickers.filter((item) => {
            return item.stickerId !== sticker.stickerId;
          });
          this.$emit('pushResult', ({stickerForDelete: sticker}));
        } else if (sticker.newId) {
          this.newStickers = this.newStickers.filter((item) => {
            return item.newId !== sticker.newId;
          });
          this.$emit('pushResult', ({newStickers: this.newStickers}));
        } else {
            console.warn(`Got a bug!!! Sticker without id or newId`);
        }
        this.stickers = this.actualStickers.concat(this.newStickers);
        this.stickers = this.stickers.map((item) => {
          item.selected = (item.stickerId === this.coverId || item.newId === this.coverId);
          return item;
        });
      }
    },
    mounted: function () {
      this.actualStickers = Object.assign(this.inputData || [], []);
      this.coverId = this.coverData;
      this.stickers = this.actualStickers.concat(this.newStickers);
      this.stickers = this.stickers.map((item) => {
        item.selected = (item.stickerId === this.coverId || item.newId === this.coverId);
        return item;
      });
    }
  }
</script>

<style scoped>
    .pseudoButton {
        display: inline-block;
        border: solid 2px wheat;
        padding: 4px;
        margin: 0 3px;
        width: 30px;
        line-height: 30px;
        border-radius: 30px;
        cursor: pointer;
        font-weight: bold;
        font-size: 25px;
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        background: whitesmoke;
        opacity: 0.9;
        box-shadow: 0 0 2px 2px wheat;
    }

    .closeButton {
        position: absolute;
        top: 15px;
        right: 8px;
    }

    .cover {
        -webkit-text-stroke: 2px whitesmoke;
        color: orangered;
        font-size: 40px;
        font-weight: bolder;
        font-stretch: ultra-expanded;
        position: absolute;
        bottom: 30px;
        left: 40%;
    }

    .spinnerPosition {
        z-index: 1000;
        float: right;
        padding: 30px;
        background: white;
        border-radius: 50px;
        box-shadow: 0 0 10px 8px dimgray;
    }

    .main {
        margin: 5px 10px;
        border: solid 3px cornflowerblue;
        border-radius: 10px;
        height: 640px;
        background: aliceblue;
        font-size: 25px;
        overflow-y: auto;
    }

    .preview {
        height: 300px;
        margin: 10px 5px;
        border: solid 2px darkslateblue;
    }

    .previewSelected {
        height: 300px;
        margin: 10px 5px;
        border: solid 4px darkslateblue;
        box-shadow: 0 0 4px 2px darkslateblue;
    }

    .flexTable {
        display: flex;
        flex-wrap: wrap;
    }

    .flexColumn {
        flex-direction: column;
    }
</style>

import Vue from 'vue';
// Use DB
import Vuex from 'vuex';
import store from './store';
Vue.use(Vuex);
// Use meta tags
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
// Use swal2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
// Main application
import App from './App.vue';


Vue.config.productionTip = false;

new Vue({
  title: 'StickMe',
  metaInfo: () => ({
    title: 'StickMe',
    titleTemplate: '%s | StickMe',
    htmlAttrs: {
      lang: 'en',
      amp: undefined
    },
    headAttrs: {
      test: true
    },
    bodyAttrs: {
      tabIndex: 0
    },
    meta: [
      //{ name: 'description', content: 'Hello', vmid: 'test' }
    ],
    script: [
     // { innerHTML: '{ "@context": "http://www.schema.org", "@type": "Organization" }', type: 'application/ld+json' },
     // { innerHTML: '{ "body": "yes" }', body: true, type: 'application/ld+json' }
    ],
    __dangerouslyDisableSanitizers: ['script']
  }),
  el: '#app',
  store,
  render: h => h(App)
}).$mount('#app');

<template>
    <div class="main">
        <draggable class="flexColumn" v-model="allCategories"
                   draggable="div" group="a">
            <span slot="header" style="user-select: none;">All:</span>
            <div class="preview"
                 v-for="item of allCategories" :key="item.id">
                {{item ? item.name.en : ''}}
            </div>
        </draggable>
        <draggable class="flexColumn" v-model="selectedCategories" draggable="div" group="a">
            <span slot="header" style="user-select: none;">Selected:</span>
            <div class="preview"
                 v-for="selectedItem of selectedCategories"
                 :key="selectedItem.index">
                {{selectedItem ? selectedItem.name.en : ''}}
            </div>
        </draggable>
    </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import { mapGetters } from 'vuex'

  export default {
    name: 'SelectCategories',
    components: {
      draggable
    },
    props: {
      inputArray: Array
    },
    computed: {
      ...mapGetters('categories', {
        categories: 'getCategories'
      })
    },
    data: function () {
      return {
        allCategories: [],
        selectedCategories: [],
        editable: true
      };
    },
    methods: {
      fillControls: async function () {
        this.allCategories = Object.assign(this.categories, []);
        if (this.inputArray && this.allCategories.length) {
          const selectedCategoriesObj = {};
          this.allCategories = this.allCategories.filter((item) => {
            if (this.inputArray.indexOf(item.id) !== -1) {
              selectedCategoriesObj[item.id] = item;
              return false;
            } else return true;
          });
          this.selectedCategories = this.inputArray.map((id) => {
            return selectedCategoriesObj[id];
          });
          console.log(this.selectedCategories, this.inputArray, this.allCategories);
        }
      }
    },
    watch: {
      categories() {
        this.fillControls();
      },
      selectedCategories() {
        this.$emit('pushResult', ({selectedCategories: this.selectedCategories}));
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.fillControls();
      })
    }
  }
</script>

<style scoped>
    .main {
        display: flex;
        min-height: 80px;
    }

    .flexColumn {
        flex-flow: column;
        flex-basis: 50%;
        border: solid 2px sandybrown;
    }

    .dragItem, .preview {
        text-overflow: ellipsis;
        border: solid 1px cornflowerblue;
        border-radius: 7px;
        box-shadow: 0 0 3px 1px dimgray;
        background: #42b983;
        margin: 6px 50px;
        cursor: pointer;
    }

    .preview {
        background: wheat;
    }
</style>

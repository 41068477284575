<template>
    <div id="app">
        <div class="app_header">
            <div class="app_header_column title">
                <span>Welcome to StickMe admin system</span>
            </div>
            <div class="app_header_column login" v-if="!isLogged">
                <div class="loginBody">
                    <input type="button" v-on:click="loginClick" value="Login"/>
                </div>
            </div>
            <div class="app_header_column login" v-if="isLogged">
                <div class="loginBody">
                    <span style="cursor: pointer;" v-on:click="showUserInfo">{{userName}} </span>
                    <input type="button" v-on:click="logoutClick" value="Logout"/>
                </div>
            </div>
        </div>
        <div class="app_menu">
            <div class="app_menu_column errors">
                <span>Errors:</span>
                <ul v-if="errors && errors.length">
                    <li v-for="error of errors" :key="error.createdAt">
                        {{error.message}}
                    </li>
                </ul>
            </div>
            <div class="app_menu_column least">
                <div>
                    <span>Switch {{isStickerPacksMode ? 'off to categories' : 'on to sticker packs'}}: </span>
                    <label class="switch">
                        <input type="checkbox" v-model="isStickerPacksMode">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <component class="modalComponent" v-bind:is="modalComponent"
                   v-on:modalComponentResult="modalComponentResult"></component>
        <component v-bind:logged="isLogged" class="bodyComponent" v-bind:is="bodyComponent"
                   v-on:bodyComponentResult="bodyComponentResult"></component>
        <pulse-loader class="spinnerPosition" :loading="isShowSpinner"></pulse-loader>
    </div>
</template>

<script>
  import AuthModule from './modules/auth';
  import LoginComponent from './components/Login';
  import CategoriesComponent from './components/Categories';
  import StickerPacksComponent from './components/StickerPacks';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

  export default {
    name: 'App',
    components: {
      LoginComponent,
      PulseLoader,
      CategoriesComponent,
      StickerPacksComponent
    },
    data() {
      return {
        userName: null,
        isLogged: false,
        isStickerPacksMode: true,
        //
        modalComponent: null,
        bodyComponent: null,
        isShowSpinner: false,
        errors: []
      }
    },
    methods: {
      updatedError: function (err) {
        console.warn(`Global get error`, err);
        alert(err.message);
        this.errors.push(err);
      },
      modalComponentResult: function (result) {
        if (result.err) this.updatedError(result.err);
        else if (result.close) this.modalComponent = null;
      },
      bodyComponentResult: function (result) {
        if (result.err) this.updatedError(result.err);
        else if (result.close) this.bodyComponent = null;
      },
      loginClick: function () {
        this.modalComponent = 'LoginComponent';
      },
      logoutClick: function () {
        AuthModule.logout((err) => {
          if (err) return alert(err.message);
          this.userName = null;
          this.isLogged = false;
        });
      },
      showUserInfo: function () {
        AuthModule.getCurrentUserToken().then((token) => {
          this.$swal({
            icon: 'info',
            title: 'User info',
            width: 800,
            html: `<div style="font-size: 15px; line-height: 15px; height: 15px;"><span style="display: inline-block; width: 20%; text-align: right;">User token - </span><input id="user_info_token" style="display: inline-block; width: 40%;" value="${token}"><input type="button" onclick="const copyText = document.getElementById('user_info_token');console.log(copyText);copyText.select();copyText.setSelectionRange(0, 99999);document.execCommand('copy');" value="Copy"></div>`
          });
        }).catch((err) => {
          this.$swal({
            icon: 'error',
            title: 'User info',
            text: `Something wrong - ${err}`
          });
        });
      }
    },
    watch: {
      isStickerPacksMode() {
        if (this.bodyComponent === 'StickerPacksComponent' && !this.isStickerPacksMode) {
          this.bodyComponent = 'CategoriesComponent';
        } else if (this.bodyComponent === 'CategoriesComponent' && this.isStickerPacksMode) {
          this.bodyComponent = 'StickerPacksComponent';
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.isShowSpinner = true;
        AuthModule.init((data) => {
          const currentUser = data;
          this.isShowSpinner = false;
          if (currentUser) {
            this.userName = currentUser.email ? currentUser.email.split('@')[0] : 'Anonymous';
            this.isLogged = true;
            this.bodyComponent = 'StickerPacksComponent';
          } else {
            this.userName = null;
            this.isLogged = false;
            this.bodyComponent = null;
          }
        });
      });
    }
  }
</script>

<style>
    span, input {
        vertical-align: middle;
        line-height: 30px;
        font-size: 25px;
        text-overflow: ellipsis;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    .app_header, .app_menu {
        display: flex;
    }

    .app_header_column, .app_menu_column {
        flex-direction: column;
        font-size: 16px;
        font-weight: bold;
        padding: 0 10px;
    }

    .app_menu_column {
        border: solid 1px darkmagenta;
        height: 150px;
    }

    .app_header_column.title {
        padding: 7px;
        flex-basis: 81%;
    }

    .app_header_column.login {
        flex-basis: 19%;
    }

    .loginBody {
        border: solid 1px sandybrown;
        border-radius: 5px;
        padding: 7px;
    }

    .app_menu_column.errors {
        flex-basis: 75%;
        overflow-y: scroll;
        border: solid 1px darkmagenta;
    }

    .app_menu_column.least {
        flex-basis: 25%;
        border: solid 1px darkmagenta;
    }

    .spinnerPosition {
        z-index: 1000;
        position: absolute;
        top: 45%;
        left: 45%;
        padding: 50px;
        background: white;
        border-radius: 50px;
        box-shadow: 0 0 15px 10px dimgrey;
    }

    .modalComponent {
        z-index: 1;
        padding: 35px 0 5px 0;
        position: fixed;
        top: 35%;
        border: solid 3px cornflowerblue;
        border-radius: 10px;
        left: 30%;
        background: aliceblue;
        width: 40%;
        min-width: 200px;
        height: 30%;
        min-height: 160px;
    }

    /* Rounded sliders */
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>

import axios from 'axios';

import FireStorageModule from '../../modules/fireStorage';
import AuthModule from '../../modules/auth';
import Helpers from '../../modules/helpers';

const state = () => ({
  stickerPacks: [],
  stickerPacksSortType: {
    attr: 'name',
    isAsk: true
  },
  stickers: {}
});

const getters = {
  getStickerPacks: (state) => {
    state.stickerPacks.sort((a, b) => {
      if (state.stickerPacksSortType.attr === 'name') return a.name.toLowerCase() > b.name.toLowerCase() && state.stickerPacksSortType.isAsk ? 1 : -1;
      else if (state.stickerPacksSortType.attr === 'updatedAt') return a.updatedAt._seconds - b.updatedAt._seconds > 0 && state.stickerPacksSortType.isAsk ? 1 : -1;
      return -1;
    });
    return state.stickerPacks.map((item, index)=> {
      if (item.createdAt instanceof Date) {
        item.createdAtDate = item.createdAt.toISOString().slice(0, -5);
        item.updatedAt = item.updatedAt || item.createdAt;
        item.updatedAtDate = item.updatedAt.toISOString().slice(0, -5);
      } else if (item.createdAt._seconds) {
        item.createdAtDate = (new Date(item.createdAt._seconds * 1000)).toISOString().slice(0, -5);
        item.updatedAt = item.updatedAt || item.createdAt;
        item.updatedAtDate = (new Date(item.updatedAt._seconds * 1000)).toISOString().slice(0, -5);
      }
      item.sortIndex = index;
      return item;
    });
  },
  getStickers: (state) => {
    return state.stickers;
  }
};

const actions = {
  setStickerPacksSortType({commit}, data) {
    commit('setStickerPacksSortType', data);
  },
  setStickerPacks({commit}) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.get(`${Helpers.mainUrl}/api/v1/stickerpack/all`, {
          headers: {
            Authorization: "Bearer " + token
          }
        }).then(res => {
          commit('setStickerPacks', res.data.payload);
          commit('clearStickers');
          const promises = [];
          if (res.data.payload.length) {
            for (const item of res.data.payload) {
              if (item.stickers.length) {
                for (const stickerId of item.stickers) {
                  promises.push(new Promise((resolve) => {
                    FireStorageModule.getObject(item.userId + '/' + item.id + '/' + stickerId, (err, result) => {
                      if (err) {
                        console.warn(`HTTP get sticker from storage get err - ${err.message}`);
                        alert(`HTTP get sticker from storage get err - ${err.message}`);
                        return resolve({
                          packageId: item.id,
                          stickerId: stickerId,
                          stickerUrl: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKC" +
                            "wsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQ" +
                            "UFBQUFBQUFBQUFBQUFBQUFBT/wAARCABQAGQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAw" +
                            "UFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZW" +
                            "ZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn" +
                            "6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMz" +
                            "UvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqK" +
                            "mqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD7F6mkzgmlo9aAACjPFAooATIpc0UUAFG" +
                            "aKKADNIeDS0d6AAdaM0CigBD1oobrRQAvekH3jS0n8RoAB0pRSDpWvpHhbVNbINrZuYz/AMtZPkT8z1/DNAGR1/OnxRPPKscaNJI3ARAST+Ar0bS/" +
                            "hZBChm1G5e6ZesFpwCfTJ5P6VclOr6VG0OgeGUsk6efK6Fz74DfzJoA5TTPh1qd3F5135el2wGWe4PzAf7vb8SKo+LPDf/CNajHCspuIJYhJHKQB" +
                            "u7Hp7/zFaWqeG/F2svuvbee49FaVNo+ig4Fbup+GdT1jwVYRTWrLqli+xULLl06dc46YP/AaAPNaQ/eFbOoeEdX0u0e5urJoYExucupxk4HQ+prG/" +
                            "ioAXuaKO5ooARutFDdaKAFyKTOCaXHvSYyTQAA8V37and6t8Nlkt7mWKfT3Ec4jbaXjHAzjnoQfwNcAOldb8OL9I9Wm0645ttQiMTKem4A4/Mbh+V" +
                            "AFz+0bnTvhnZTWtxJbym8YF42wSMvkZps1j4pg8OjV21ibytglMImO8Ie/p05xUmv6e+l/D2Gzkzvh1FkJ9RufB/EYNYun32veILD+xbV3uLdEyYw" +
                            "AMKOgLenoKAKn/CV60SANVvCTwAJTUlx4j160nkhm1K9iljbayNIcg+ldH8OvCEkt82oX0LxpbOViilXBMg6kg+n8/pW/498L2l3pF/qEVuov1VZDK" +
                            "OrBeo/75z+VAHOwaldal8NNWlu7iS5kW5VQ0rZIGU4/WuFzzXZaX/yTHWOf+Xpf5x1xv8QoAAeaXNIBg0tADSc0UHrRQA7NJn5jS5GaTPzGgBQakt7" +
                            "iS0uIp4jtliYOh9wcioh0pc0Aet+Lrefxb4Ss5NNhM7TSRzbAQMDac9SOhOKoeHU1zw3YC3t/DIZjzJM10oaRvU/0HavP7bXNQs4Vhgv7mGJeiRys" +
                            "FH4ZqX/hJdWz/wAhS7/7/t/jQB6h/b/ib/oWh/4FrTJda8RzxvG/hlWR1KsPta8g8GvMv+El1b/oKXf/AH/b/Gj/AISXVv8AoKXf/f8Ab/GgDrf7H" +
                            "vNE+HGrQXsBt5GuFdVLA/LlB2PtXAHqKvXOuaheQtDPf3E8TdUklLKfwJqj/FQAveikHU0uaAEPWikPWigB2T6UZ5pufalPU8UAKDntRmm0v4UAGf" +
                            "al/Cm/hR+FADs+1H4U38KO3SgB2T6UE803PHSjigBwNGaQdaAenFACHrRRRQB//9k="
                        });
                      }
                      resolve({
                        packageId: item.id,
                        stickerId: stickerId,
                        stickerUrl: result
                      });
                    });
                  }));
                }
              }
            }
          }
          if (!promises.length) return resolve(true);
          Promise.all(promises).then((result) => {
            commit('addSticker', result);
            resolve(true);
          }).catch((err) => {
            console.warn(`HTTP setStickerPacks get err - ${err.message}`);
            reject({
              message: `HTTP setStickerPacks get err - ${err.message}`,
              createdAt: new Date()
            });
          });
        }).catch(err => {
          console.warn(`HTTP setStickerPacks get err - ${err.message}`);
          reject({
            message: `HTTP setStickerPacks get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  createStickerPack({commit}, data) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.post(`${Helpers.mainUrl}/api/v1/stickerpack/create`, data, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }).then(res => {
          if (res.data.payload && res.data.payload.hasError) {
            console.warn(`HTTP createStickerPack get err - ${res.data.payload.errorMessage}`);
            reject({
              message: `HTTP createStickerPack get err - ${res.data.payload.errorMessage}`,
              createdAt: new Date()
            });
          } else {
            commit('createStickerPack', res.data.payload);
            resolve(res.data.payload);
          }
        }).catch(err => {
          console.warn(`HTTP createStickerPack get err - ${err.message}`);
          reject({
            message: `HTTP createStickerPack get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  updateStickerPack({commit}, data) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.put(`${Helpers.mainUrl}/api/v1/stickerpack/update`, data, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }).then(res => {
          if (res.data.payload && res.data.payload.hasError) {
            console.warn(`HTTP updateStickerPack get err - ${res.data.payload.errorMessage}`);
            reject({
              message: `HTTP updateStickerPack get err - ${res.data.payload.errorMessage}`,
              createdAt: new Date()
            });
          } else {
            commit('updateStickerPack', res.data.payload);
            resolve(res.data.payload);
          }
        }).catch(err => {
          console.warn(`HTTP updateStickerPack get err - ${err.message}`);
          reject({
            message: `HTTP updateStickerPack get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  deleteStickerPack({commit}, id) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.delete(`${Helpers.mainUrl}/api/v1/stickerpack/delete?stickerPackId=${id}`, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }).then(res => {
          if (res.data.payload && res.data.payload.hasError) {
            console.warn(`HTTP deleteStickerPack get err - ${res.data.payload.errorMessage}`);
            reject({
              message: `HTTP deleteStickerPack get err - ${res.data.payload.errorMessage}`,
              createdAt: new Date()
            });
          } else {
            commit('deleteStickerPack', id);
            resolve(true);
          }
        }).catch(err => {
          console.warn(`HTTP deleteStickerPack get err - ${err.message}`);
          reject({
            message: `HTTP deleteStickerPack get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  // eslint-disable-next-line no-unused-vars
  uploadSticker({commit}, data) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.post(`${Helpers.mainUrl}/api/v1/stickers/uploadString`, {
          packageId: data.packageId,
          file: data.file,
          contentType: data.contentType,
          notUpdateStickerPack: true
        }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }).then((res) => {
          if (res.data.payload && res.data.payload.hasError) {
            console.warn(`HTTP uploadSticker get err - ${res.data.payload.errorMessage}`);
            reject({
              message: `HTTP uploadSticker get err - ${res.data.payload.errorMessage}`,
              createdAt: new Date()
            });
          } else {
            FireStorageModule.getObject(data.userId + '/' + data.packageId + '/' + res.data.payload, (err, result) => {
              if (err) {
                console.warn(`HTTP uploadSticker get err - ${err.message}`);
                return reject({
                  message: `HTTP uploadSticker get err - ${err.message}`,
                  createdAt: new Date()
                });
              }
              commit('addSticker', {
                packageId: data.packageId,
                stickerId: res.data.payload,
                stickerUrl: result
              });
              resolve(res.data.payload);
            });
          }
        }).catch(err => {
          console.warn(`HTTP uploadSticker get err - ${err.message}`);
          reject({
            message: `HTTP uploadSticker get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  // eslint-disable-next-line no-unused-vars
  deleteSticker({commit}, data) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.delete(`${Helpers.mainUrl}/api/v1/stickers/delete?stickerId=${data.stickerId}&packageId=${data.packageId}`, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }).then((res) => {
          if (res.data.payload && res.data.payload.hasError) {
            console.warn(`HTTP deleteSticker get err - ${res.data.payload.errorMessage}`);
            reject({
              message: `HTTP deleteSticker get err - ${res.data.payload.errorMessage}`,
              createdAt: new Date()
            });
          } else {
            commit('deleteSticker', {
              packageId: data.packageId,
              stickerId: data.stickerId
            });
            resolve(true);
          }
        }).catch(err => {
          console.warn(`HTTP uploadSticker get err - ${err.message}`);
          reject({
            message: `HTTP uploadSticker get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  },
  updateCover({commit}, data) {
    return new Promise((resolve, reject) => {
      AuthModule.getCurrentUserToken().then((token) => {
        axios.post(`${Helpers.mainUrl}/api/v1/stickerpack/updateCover`, data.dataForUpdateCover, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }).then((res) => {
          if (res.data.payload && res.data.payload.hasError) {
            console.warn(`HTTP updateCover get err - ${res.data.payload.errorMessage}`);
            reject({
              message: `HTTP updateCover get err - ${res.data.payload.errorMessage}`,
              createdAt: new Date()
            });
          } else {
            commit('updateStickerPack', data.stickerPack);
            resolve(true);
          }
        }).catch(err => {
          console.warn(`HTTP updateCover get err - ${err.message}`);
          reject({
            message: `HTTP updateCover get err - ${err.message}`,
            createdAt: new Date()
          });
        });
      }).catch((err) => reject(err));
    });
  }
};

const mutations = {
  setStickerPacksSortType(state, data) {
    state.stickerPacksSortType = data;
  },
  setStickerPacks(state, stickerPacks) {
    state.stickerPacks = stickerPacks;
  },
  createStickerPack(state, stickerPack) {
    state.stickerPacks.push(stickerPack);
  },
  updateStickerPack(state, stickerPack) {
    state.stickerPacks = state.stickerPacks.filter((item) => {
      return item.id !== stickerPack.id;
    });
    state.stickerPacks.push(stickerPack);
  },
  deleteStickerPack(state, id) {
    state.stickerPacks = state.stickerPacks.filter((item) => {
      return item.id !== id;
    });
  },
  clearStickers(state) {
    state.stickers = {};
  },
  addSticker(state, data) {
    if (Array.isArray(data)) {
      data.forEach((item) => {
        if (state.stickers[item.packageId]) state.stickers[item.packageId].push(item);
        else state.stickers[item.packageId] = [item];
      });
    } else {
      if (state.stickers[data.packageId]) state.stickers[data.packageId].push(data);
      else state.stickers[data.packageId] = [data];
    }
  },
  deleteSticker(state, data) {
    if (Array.isArray(state.stickers[data.packageId])) {
      if (Array.isArray(data)) {
        state.stickers[data.packageId] = state.stickers[data.packageId].filter((item) => {
          return !data.find((searchItem) => {
            return item.stickerId === searchItem.stickerId;
          });
        });
      } else {
        state.stickers[data.packageId] = state.stickers[data.packageId].filter((item) => {
          return item.stickerId !== data.stickerId;
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

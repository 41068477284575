import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCrn5Av9x388b0vmd74w-4nEBJxiWT9wGU",
  authDomain: "stickme-675c7.firebaseapp.com",
  databaseURL: "https://stickme-675c7.firebaseio.com",
  projectId: "stickme-675c7",
  storageBucket: "stickme-675c7.appspot.com",
  messagingSenderId: "789911602947",
  appId: "1:789911602947:web:9e2afe4f765821a39a8b36",
  measurementId: "G-9DF4M9TSFC"
};

let currentUser = null;
let currentUserToken = null;
let currentUserTokenExpiredDate = null;

function getCurrentUser() {
  return currentUser;
}

function refreshCurrentUserToken() {
  return new Promise((resolve, reject) => {
    if (!currentUser) return reject(`Bad user`);
    currentUser.getIdToken().then((result) => {
      const actualDate = new Date();
      actualDate.setMinutes(actualDate.getMinutes() + 56);
      currentUserTokenExpiredDate = actualDate.getTime();
      currentUserToken = result;
      resolve(result);
    }).catch((err) => {
      console.warn(`refreshCurrentUserToken get err`, currentUser, err);
      reject(`Get err - ${err.message}` );
    });
  });
}

function getCurrentUserToken() {
  if (currentUserToken) return refreshCurrentUserToken();
  const actualDate = new Date();
  if (currentUserTokenExpiredDate < actualDate.getTime()) return refreshCurrentUserToken();
  return Promise.resolve(currentUserToken);
}

function getFireStorage() {
  return firebase.storage();
}

function init(cb) {
  firebase.initializeApp(firebaseConfig);
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      currentUser = user;
      console.log(user);
      refreshCurrentUserToken();
      cb(currentUser);
    } else {
      currentUser = null;
      cb(currentUser);
    }
  });
}

function login(email, password, cb) {
  if (!email || !password || email.length < 2 || password.length < 1) return cb(new Error('Bad email or password'));
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }).then(() => {
    console.warn(`signInWithEmailAndPassword ready`);
    cb();
  }).catch((err) => {
    console.warn(`signInWithEmailAndPassword get err`, err);
    cb(err);
  });
}

function logout(cb) {
  firebase.auth().signOut().then(() => {
    console.warn(`signOut ready`);
    cb();
  }).catch((err) => {
    console.warn(`signOut get err`, err);
    cb(err);
  });
}

function getAuthor() {
  let author = null;
  switch (currentUser.email) {
    case 'zeryen1@gmail.com':
      author = 'StickMe';
      break;
    case 'a.zarovskiy@dreamteam-mobile.com':
      author = 'Anonymous';
      break;
    default:
      author = currentUser.email;
  }
  return author;
}

export default {
  getAuthor,
  getCurrentUser,
  getCurrentUserToken,
  firebaseConfig,
  getFireStorage,
  init,
  login,
  logout
};

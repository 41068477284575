<template>
    <div class="modalBody">
        <div class="modalRow">Login</div>
        <div class="modalRow">
            <span>Email: </span>
            <input ref="emailEl" v-model="email" v-on:keydown="keyboardPress">
        </div>
        <div class="modalRow">
            <span>Password: </span>
            <input type="password" v-model="password" v-on:keydown="keyboardPress">
        </div>
        <div class="modalRow">
            <input type="button" v-on:click="closeComponent" value="Cancel">
            <input type="button" v-on:click="logging" value="Submit">
        </div>
        <pulse-loader class="spinnerPosition" :loading="isShowSpinner"></pulse-loader>
        <div class="modalWindowClose" v-on:click="closeComponent">X</div>
    </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import AuthModule from '../modules/auth';

  export default {
    name: "Login",
    components: {
      PulseLoader
    },
    data() {
      return {
        isShowSpinner: false,
        email: null,
        password: null
      }
    },
    methods: {
      keyboardPress: function(el) {
        if (el.key === 'Enter') this.logging();
      },
      logging: function () {
        this.isShowSpinner = true;
        AuthModule.login(this.email, this.password, (err) => {
          this.isShowSpinner = false;
          if (err) return alert(err.message);
          this.$emit('modalComponentResult', ({close: true}));
        });
      },
      closeComponent: function () {
        console.log(`closeComponent`);
        this.$emit('modalComponentResult', ({close: true}));
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.$refs.emailEl.focus();
      });
    }
  }
</script>

<style scoped>
    input {
        min-width: 200px;
        width: 67%;
        padding-right: 5px;
    }

    span {
        min-width: 80px;
        width: 27%;
        text-align: right;
        padding-right: 5px;
    }

    input[type=button] {
        min-width: 100px;
        width: 20%;
    }

    .modalBody {
        height: 20%;
        min-height: 250px;
    }

    .modalRow {
        margin: 10px 0;
        line-height: 30px;
        height: 40px;
        font-size: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .spinnerPosition {
        z-index: 1000;
        position: absolute;
        top: 45%;
        left: 45%;
        padding: 50px;
        background: white;
        border-radius: 50px;
        box-shadow: 0 0 10px 8px dimgray;
    }

    .modalWindowClose {
        opacity: 70%;
        border: solid 2px wheat;
        padding: 0;
        margin: 5px;
        border-radius: 10px;
        box-shadow: 2px 2px 4px 1px black;
        cursor: pointer;
        font-weight: bold;
        font-size: 25px;
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        right: 0;
        top: 0;
        position: absolute;
        display: block;
        width: 25px;
        height: 25px;
        line-height: 25px;
    }

    .modalWindowClose:hover {
        box-shadow: 2px 2px 6px 2px black;
    }
</style>

<template>
    <div class="categories">
        <div class="componentBody">
            <div class="componentMenu">
                <span>Categories feed order: </span>
                <span class="pseudo-button" v-on:click="listCategoriesFeedOrder">&#x21bb;</span>
                <span class="pseudo-button" v-on:click="showEditCategoriesFeedOrder">+</span>
            </div>
            <div class="flexColumn" style="flex-wrap: wrap; align-content: center; justify-content: center;">
                <div class="categoryPreview" v-for="(category, index) in categoriesFeedOrder" :key="index">
                    {{(categories.find((item) => {
                    return item.id === category;
                    }) || {name: {en: category} }).name.en
                    }}
                </div>
            </div>
        </div>
        <div v-if="isShowEditCategoriesFeedOrderModal" class="modalWindow">
            <div class="modalWindowClose" v-on:click="cancelAnyModal">x</div>
            <div class="modalWindowBody">
                <div class="localizations flexRow">
                    <SelectCategories :inputArray="categoriesFeedOrder || []" v-on:pushResult="editCategoriesFeedOrderResult"/>
                    <br>
                </div>
                <div>
                    <b class="pseudo-button" v-on:click="acceptEditCategoriesFeedOrderResult">Ok</b>
                    <b class="pseudo-button" v-on:click="cancelAnyModal()">Cancel</b>
                </div>
            </div>
        </div>
        <div class="componentBody">
            <div class="componentMenu">
                <span>Categories: </span>
                <span class="pseudo-button" v-on:click="listCategories">&#x21bb;</span>
                <span class="pseudo-button" v-on:click="showCreateCategoryModal">+</span>
            </div>
            <div class="flexTable">
                <div class="flexColumn">
                    <div class="flexCell" style="flex-grow: 1;">id</div>
                    <div class="flexCell" style="flex-grow: 7;">Name</div>
                    <div class="flexCell" style="flex-grow: 1;">Actions</div>
                </div>
                <div class="flexColumn" v-for="(category, index) in categories" :key="index">
                    <div class="flexCell" style="flex-grow: 1;">{{category.id}}</div>
                    <div class="flexCell" style="flex-grow: 7;">{{category.name}}</div>
                    <div class="flexCell" style="flex-grow: 1;">
                        <b class="pseudo-button" v-on:click="showEditCategory(category)">&#x270e;</b>
                        <b class="pseudo-button" v-on:click="showDeleteCategory(category)">&#x1f5d1;</b>
                    </div>
                </div>
            </div>
            <pulse-loader class="spinnerPosition" :loading="isShowSpinner"></pulse-loader>
        </div>
        <div v-if="isShowCreateOrUpdateCategoryModal" class="modalWindow">
            <div class="modalWindowClose" v-on:click="cancelAnyModal">x</div>
            <div class="modalWindowBody">
                <div class="localizations flexRow">
                    <div class="localizationsCaption">Names (localized):</div>
                    <div class="localizationsInput flexColumn" v-for="(localizedName, index) in newNames"
                         :key="index">
                        <div class="localizationsName">{{localizedName.name}}:</div>
                        <input type="text" v-model="localizedName.value"/>
                    </div>
                </div>
                <div>
                    <b v-if="!activeCategory" class="pseudo-button" v-on:click="acceptCreateCategory()">Ok</b>
                    <b v-if="activeCategory" class="pseudo-button" v-on:click="acceptEditCategory()">Ok</b>
                    <b class="pseudo-button" v-on:click="cancelAnyModal()">Cancel</b>
                </div>
            </div>
        </div>
        <div v-if="isShowDeleteCategoryModal" class="modalWindow delete">
            <div class="modalWindowClose" v-on:click="cancelAnyModal">x</div>
            <div class="modalWindowBody">
                <div>
                    <div style="margin: 5px;">Are you want delete category with english name =
                        {{activeCategory.name['en'] || ''}}
                    </div>
                    <div>
                        <b class="pseudo-button" v-on:click="acceptDeleteCategory()">Ok</b>
                        <b class="pseudo-button" v-on:click="cancelAnyModal()">Cancel</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import SelectCategories from './SelectCategories';
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Categories',
    props: ['logged'],
    components: {
      PulseLoader,
      SelectCategories
    },
    computed: {
      ...mapGetters('categories', {
        categories: 'getCategories',
        categoriesFeedOrder: 'getCategoriesFeedOrder'
      })
    },
    data() {
      return {
        localizationLanguagesList: [{code: 'ko', name: 'Korean'}, {code: 'ar', name: 'Arabic'}, {
          code: 'zh',
          name: 'Chinese'
        },
          {code: 'fr', name: 'French'}, {code: 'pt', name: 'Portuguese'}, {code: 'en', name: 'English'}, {
            code: 'ru',
            name: 'Russian'
          },
          {code: 'it', name: 'Italian'}, {code: 'ja', name: 'Japanese'}, {code: 'de', name: 'German'}, {
            code: 'es',
            name: 'Spanish'
          }],
        //
        isShowEditCategoriesFeedOrderModal: false,
        isShowCreateOrUpdateCategoryModal: false,
        isShowDeleteCategoryModal: false,
        isShowCategoriesFeedOrderSpinner: false,
        isShowSpinner: false,
        spinnerCounter: 0,
        //
        newNames: null,
        activeCategory: null,
        //
        newCategoriesFeedOrder: null
      }
    },
    methods: {
      ...mapActions('categories', ['setCategories', 'createCategory', 'updateCategory', 'deleteCategory',
        'setCategoriesFeedOrder', 'updateCategoriesFeedOrder']),
      updatedError: function (err) {
        this.$emit('bodyComponentResult', ({err: err}));
      },
      listCategories: function () {
        this.showSpinner();
        // Get categories list
        this.setCategories().then(() => {
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
        }).finally(() => {
          this.hideSpinner();
        });
      },
      listCategoriesFeedOrder: function () {
        this.showSpinner();
        // Get categories feed order list
        this.setCategoriesFeedOrder().then(() => {
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
        }).finally(() => {
          this.hideSpinner();
        });
      },
      showCreateCategoryModal: function () {
        this.isShowCreateOrUpdateCategoryModal = true;
        this.newNames = this.localizationLanguagesList.map((item) => {
          return {
            name: item.name,
            code: item.code,
            value: ''
          }
        });
      },
      acceptCreateCategory: function () {
        this.showSpinner();
        const localizedNamesObj = {};
        this.newNames.forEach((item) => {
          localizedNamesObj[item.code] = item.value;
        });
        this.createCategory({
          name: localizedNamesObj
        }).then(async (result) => {
          if (result) alert(`Category created`);
          else alert('Category create but something bad. Please refresh page and try edit sticker pack.');
          this.cancelAnyModal();
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
          this.cancelAnyModal();
        });
      },
      showEditCategory: function (category) {
        this.isShowCreateOrUpdateCategoryModal = true;
        this.activeCategory = category;
        this.newNames = this.localizationLanguagesList.map((item) => {
          return {
            name: item.name,
            code: item.code,
            value: category.name[item.code] || ''
          }
        });
      },
      acceptEditCategory: function () {
        this.showSpinner();
        const localizedNamesObj = {};
        this.newNames.forEach((item) => {
          localizedNamesObj[item.code] = item.value;
        });
        this.updateCategory({
          id: this.activeCategory.id,
          name: localizedNamesObj
        }).then(async (result) => {
          if (result) alert('Category updated');
          this.cancelAnyModal();
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
          this.cancelAnyModal();
        });
      },
      showDeleteCategory: function (category) {
        this.activeCategory = category;
        this.isShowDeleteCategoryModal = true;
      },
      acceptDeleteCategory: function () {
        this.showSpinner();
        this.deleteCategory(this.activeCategory.id).then((result) => {
          if (result) alert(`Category deleted`);
          this.cancelAnyModal();
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
          this.cancelAnyModal();
        });
      },
      showEditCategoriesFeedOrder: function () {
        this.isShowEditCategoriesFeedOrderModal = true;
        this.newCategoriesFeedOrder = [];
      },
      editCategoriesFeedOrderResult: function(data) {
        if (data.selectedCategories) this.newCategoriesFeedOrder = data.selectedCategories;
      },
      acceptEditCategoriesFeedOrderResult: function () {
        this.showSpinner();
        this.updateCategoriesFeedOrder({
          categories: this.newCategoriesFeedOrder.map((item) => item.id)
        }).then((result) => {
          if (result) alert(`Categories feed order updated`);
          this.cancelAnyModal();
        }).catch((err) => {
          this.$emit('bodyComponentResult', ({err: err}));
          this.cancelAnyModal();
        });
      },
      cancelAnyModal: function () {
        this.isShowEditCategoriesFeedOrderModal = false;
        this.isShowCreateOrUpdateCategoryModal = false;
        this.isShowDeleteCategoryModal = false;
        this.hideSpinner();
        //
        this.newNames = null;
        this.activeCategory = null;
        this.newCategoriesFeedOrder = null;
      },
      showSpinner: function () {
        this.spinnerCounter++;
        this.isShowSpinner = true;
      },
      hideSpinner: function () {
        if (--this.spinnerCounter < 1) this.isShowSpinner = false;
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.listCategories();
        this.listCategoriesFeedOrder();
      });
    }
  }
</script>

<style scoped>
    .pseudo-button {
        display: inline-block;
        border: solid 2px wheat;
        padding: 5px;
        margin: 2px 3px;
        line-height: 30px;
        border-radius: 30px;
        cursor: pointer;
        font-weight: bold;
        font-size: 25px;
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
    }

    .modalWindow {
        z-index: 1;
        padding: 35px 0 5px 0;
        width: 60%;
        height: 90%;
        position: fixed;
        top: 2%;
        border: solid 3px cornflowerblue;
        border-radius: 10px;
        left: 25%;
        background: aliceblue;
        font-size: 25px;
    }

    .modalWindow.delete {
        top: 40%;
        height: 20%;
    }

    .modalWindowBody {
        height: 97%;
        overflow: auto;
    }

    .modalWindowBody input {
        margin: 4px 0;
        padding: 0;
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 20px;
    }

    .modalWindowBody input[type="checkbox"] {
        width: 20px;
    }

    .modalWindowClose {
        right: -2px;
        top: -4px;
        position: absolute;
        display: block;
        width: 25px;
        height: 25px;
        line-height: 25px;
    }

    .stickerPacks {
        line-height: 30px;
        font-size: 25px;
    }

    .componentBody {
        margin: 5px 0;
    }

    .componentMenu {
        border: solid 2px wheat;
        width: 20%;
        margin: 5px auto;
        padding: 5px 0;
        border-radius: 5px;
    }

    .flexTable {
        display: flex;
        flex-direction: column;
        border: solid 1px wheat;
    }

    .flexRow {
        display: flex;
        flex-wrap: wrap;
    }

    .flexColumn {
        display: flex;
        flex-direction: row;
    }

    .flexCell {
        min-width: 80px;
        border: solid 1px sandybrown;
        flex-basis: 80px;
        overflow-wrap: anywhere;
        word-break: break-all;
        text-overflow: ellipsis;
    }

    .leftFlexColumn {
        text-align: right;
        flex-basis: 20%;
        margin: 0 5px;
    }

    .rightFlexColumn {
        text-align: left;
        flex-basis: 78%;
        margin: 0 5px;
    }

    .imagePreview {
        max-height: 100px;
        min-width: 90px;
        margin: 3px 3px;
        border: solid 1px darkslateblue;
    }

    .categoryPreview {
        text-overflow: ellipsis;
        border: solid 1px cornflowerblue;
        border-radius: 3px;
        box-shadow: 0 0 2px 1px dimgray;
        background: #42b983;
        margin: 3px 3px;
        padding: 1px 2px;
        line-height: 36px;
    }

    .flexRow {
        display: flex;
        flex-direction: column;
        line-height: 32px;
        flex-wrap: unset;
    }

    .flexRow > * {
        margin: 3px 0;
    }

    .localizations {
        margin: auto;
        padding: 4px 0;
        border: solid 1px wheat;
        height: 90%;
        width: 98%;
        overflow-y: auto;
    }

    .localizations.textarea {
        height: 200px;
    }

    .localizationsName {
        flex-basis: 200px;
        text-align: end;
    }

    .localizationsInput > * {
        height: 24px;
        line-height: 24px;
    }

    .localizationsTextarea > div {
        height: 60px;
        line-height: 60px;
    }

    .localizationsCaption {
        height: 24px;
        line-height: 24px;
    }
</style>

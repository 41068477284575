import Vue from 'vue';
import Vuex from 'vuex';
import categories from './modules/categories';
import stickerPacks from './modules/stickerPacks';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    categories,
    stickerPacks
  }
});
